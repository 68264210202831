import React from "react";
// import Loadable from 'react-loadable-visibility/react-loadable'
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import LazyImage from "components/LazyImage";
import Typography from "components/Typography";
import FileDownload from "components/FileDownload";
import contentful from "utils/contentful";

import { HEADING_6, BODY, IMAGE } from "./components/BlogBlocks";

export const fetchBlogPosts = async () => {
  let posts = [];

  try {
    posts = await contentful.getEntries({
      content_type: "newsArticle",
    });
    // console.log(posts)
    posts = posts.items
      .map((post) => {
        const { sys, fields } = post;

        // update structure for list page
        return {
          /**
           * @note
           * array of posts
           * struture of single post for list page:
           * {
           * title: String,
           * description: String,
           * articlePreview: String,
           * createdAt: Date,
           * heroImage: {
           *    title: String,
           *    file: { url, details, fileName, contentType },
           * },
           * tags: [],
           * }
           */
          ...fields,
          heroImage: fields.heroImage.fields,
          id: sys.id,
          createdAt: sys.createdAt,
          updatedAt: sys.updatedAt,
          displayDate: fields.publishedAt || sys.createdAt,
        };
      })
      .sort(
        (a, b) =>
          new Date(b.displayDate).getTime() - new Date(a.displayDate).getTime()
      );
  } catch (err) {
    console.log(err);
  }
  return posts;
};

export const fetchBlogPost = async (id, urlPermalink) => {
  const options = {
    // https://www.npmjs.com/package/@contentful/rich-text-react-renderer
    // replace \n with <br />
    renderText: (text) => {
      if (text === "") return [<br key={`${text}-key`} />];

      const result = text.split("\n").reduce((children, textSegment, index) => {
        // replace breaks
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);

      return result;
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, file } = node?.data?.target?.fields;

        if (!file.contentType.includes("image")) {
          return (
            <FileDownload
              type={file.contentType}
              title={title}
              size={file.details.size}
              src={file.url}
            />
          );
        }

        return <IMAGE src={file.url} alt={title} />;
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h1">{children}</Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h2">{children}</Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography variant="h3">{children}</Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => <HEADING_6>{children}</HEADING_6>,
      [BLOCKS.PARAGRAPH]: (node, children) => <BODY>{children}</BODY>,
    },
  };

  let post = null;

  try {
    if (urlPermalink) {
      const res = await contentful.getEntries({
        content_type: "newsArticle",
        "fields.urlPermalink": urlPermalink,
      });

      post = res.items[0];
    } else {
      // use ID for old posts
      post = await contentful.getEntry(id);
    }

    // parse rich text into React components for easy render
    post.fields.body = documentToReactComponents(post.fields.body, options);
  } catch (err) {
    console.log(err);
  }

  return post;
};
