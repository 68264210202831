import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import TextButton from "components/TextButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Typography from "components/Typography";
import contentful from "utils/contentful";
import pdfIcon from "assets/icons/pdf.svg";
import StockPrice from "./StockPrice";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: 36,
    color: "#c48a43",
  },
  separator: {
    borderBottom: "1px solid #d8d8d8",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  graph: {
    height: 600,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #d8d8d8",
    marginTop: 30,
  },
  content: {
    width: "100%",
    maxWidth: 1040,
    display: "flex",
    margin: "0 auto",
    padding: "20px 0",
    flexWrap: "wrap",
    justifyContent: "space-around",
    "@media (max-width: 500px)": {
      margin: 0,
      padding: 0,
    },
  },
  comingSoon: {
    fontSize: 25,
  },
  sedar: {
    fontSize: 24,
    color: "#8b8a8a",
    marginTop: 80,
    display: "block",
  },
  stockDisclaimer: {
    width: "90%",
    color: "#333",
    fontSize: 12,
    margin: "20px 0 0",
  },
}));

const useDocStyles = makeStyles((theme) => ({
  docRoot: {
    marginTop: 50,
    background: "white",
    padding: 30,
    "@media (max-width: 425px)": {
      padding: 0,
      background: "transparent",
    },
  },
  title: {
    fontSize: 24,
    margin: "0 auto 16px",
  },
  underline: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  bold: {
    fontWeight: "bold",
  },
  pdfIcon: {
    width: 16,
    paddingTop: 5,
  },
  quarterWrapper: {
    // marginBottom: 16,
    // borderBottom: '1px solid #e5dee3',
    // paddingBottom: 16,
  },
  quarterInfo: {
    display: "flex",
    alignItems: "center",
    margin: "4px 0",
    "@media (max-width: 425px)": {
      alignItems: "flex-start",
    },
  },
  quarterLabel: {
    width: 120,
    margin: "0 16px",
    textAlign: "left",
  },
  yearLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 20,
  },
  documentLink: {
    color: "#333",
    textDecoration: "underline",
    margin: "0 16px",
  },
}));

/**
 * @summary
 * To add new documents, upload to contentful assets and check the console for the financial results page.
 * Find the URL and add it to data. Refer to existing data for structure.
 */
const FinancialDocuments = () => {
  const [assets, setAssets] = useState(null);
  const classes = useDocStyles();
  const matches = useMediaQuery("(max-width: 425px)");

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const { items } = await contentful.getAssets();

        // Uncomment this to see docs :)
        console.log(
          items.map((x) => ({
            name: x.fields.title,
            url: x.fields.file.url,
          }))
        );
        setAssets(items);
      } catch (err) {
        console.log(err);
      }
    };

    fetchAssets();
  }, []);

  const data = [
    {
      year: 2024,
      documents: [
        {
          label: "Third Quarter",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/6T8XhyIv5UHtp5R4S24uoX/9f4241da8cd66f51d0d01b403affc2ee/Kadestone_Capital_Corp._-_FS_-_Sep_30_2024_-_Draft_3.pdf",
          MDA: "//assets.ctfassets.net/ukstf33kj625/6j1cXiRVo6YqtCLzYIiGRm/4e7749587be9d75308b42eee770c5d8a/Kadestone_Capital_Corp._-_MDA_-_Sep_30_2024_-_Draft_3.pdf",
        },
        {
          label: "Second Quarter",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/3HhsaLMQZBPVL6Gtm327Aq/51948a98fe92a16e377df5b685897978/Kadestone_Capital_Corp._-_FS_-_Jun_30_2024_-_Draft_4.pdf",
          MDA: "//assets.ctfassets.net/ukstf33kj625/XGc9lQBH3s1oVCyDsuUcu/a066da1165a09b041ed4c63e1b26747f/Kadestone_Capital_Corp._-_MDA_-_Jun_30_2024_-_Draft_4.pdf",
        },
        {
          label: "First Quarter",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/7bLNAycyd60xZhkuIndfaw/2372c81627e407f6983c3b0f27c04642/Kadestone_Capital_Corp._-_FS_-_Mar_31_2024_-_Draft_3.pdf",
          MDA: "//assets.ctfassets.net/ukstf33kj625/7qHkQNS3LRwZP3OBvdAdAe/7494b7dae6fea7dc65095e8928b88a98/Kadestone_Capital_Corp._-_MDA_-_Mar_31_2024_-_Draft_3.pdf",
        },
      ],
    },
    {
      year: 2023,
      documents: [
        {
          label: "Year End",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/1YufICgpy1GLsYG6COMQfq/c37e0ca0e02f911ed8c651e6caf52cf2/Kadestone_Capital_Corp._-_FS_-_Dec_31_2023_-_Draft_7_-_Final.pdf",
          MDA: "//assets.ctfassets.net/ukstf33kj625/6z9W6BbPm6yJocbWe5CFK8/3f47070d4dc3091b8fc892c275b38391/Kadestone_Capital_Corp._-_MDA_-_Dec_31_2023_-_Draft_5_-_Final.pdf",
        },
        {
          label: "Third Quarter",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/1ZeUOO39uqXW6dWpGuoDSq/f7656dad0cde0513e9b4f811c8530b7e/Kadestone_Capital_Corp._-_FS_-_Sep_30_2023_-_Draft_3.pdf",
          MDA: "//assets.ctfassets.net/ukstf33kj625/2YrQJiyQ58yNN119Uh1gFB/cdca836118baffcbfa51d9dc018945bf/Kadestone_Capital_Corp._-_MDA_-_Sep_30__2023_-_Draft_2.pdf",
        },
        {
          label: "Second Quarter",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/1E87ESY8NGnB2VAUwJIOB4/17e5a813e5a76505d0c304dde3c65b97/Kadestone_Capital_Corp._-_FS_-_Jun_30_2023_-_Draft_3.pdf",
          MDA: "//assets.ctfassets.net/ukstf33kj625/5r4BICWVP4C2PY6568IU2k/0ec818882f9eb901dabc0f665a7fa6c3/Kadestone_Capital_Corp._-_MDA_-_Jun_30__2023_-_Draft_3.pdf",
        },
        {
          label: "First Quarter",
          MDA: "//assets.ctfassets.net/ukstf33kj625/wU8LxzrNs0XYYZinNr80J/2a6c6702b545d1968ec67a2a78e8c5c6/Kadestone_Capital_Corp._-_MDA_-_Mar_31_2023_-_Draft_3.pdf",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/2v67hwH5fgc1cSAcTaVkpf/3aec9e7dd89786bf25a75cfece270a61/Kadestone_Capital_Corp._-_FS_-_Mar_31__2023_-_Draft_3.pdf",
        },
      ],
    },
    {
      year: 2022,
      documents: [
        {
          label: "Year End",
          MDA: "//assets.ctfassets.net/ukstf33kj625/2YF9IbOJ7oq3SunauyYfTe/9b144aefcc46ee4236454649741766dd/Kadestone_Capital_Corp._-_MDA_-_Dec_31_2022_-_Final.pdf",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/35u1vEznjwUFRydHPvlMvU/9a4ab5dec60fee6c8161a0711a0d64c3/Kadestone_Capital_Corp._-_FS_-_Dec_31__2022_-_Final.pdf",
        },
        {
          label: "Third Quarter",
          MDA: "//assets.ctfassets.net/ukstf33kj625/413gp5Uf7MTdEVaReuvOD2/a22b0655770104e9fc47065d8eec65e7/Kadestone_Capital_Corp._-_MDA_-_Sep_30_2022_-_Final.pdf",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/5qfKOH6byAliYsbQWyrLz3/722f84e61fa2a9c6c6c85ef5a7311723/Kadestone_Capital_Corp._-_FS_-_Sep_30_2022_-_Final.pdf",
        },
        {
          label: "Second Quarter",
          MDA: "//assets.ctfassets.net/ukstf33kj625/6pKoCb6PCiwaTEjOTswokB/86bd84c639e995ea79ab5aec06cad1df/Kadestone_Capital_Corp._-_MDA_-_Mar_31_2022_-_Final.PDF",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/1jxFYSG5ElTKrAtd4wvMJO/f24ea1a538783d3b9699a2fb8dfc2a7e/Kadestone_Capital_Corp._-_FS_-_Mar_31_2022_-_Final.PDF",
        },
        {
          label: "First Quarter",
          // these urls are supposedly consistent...
          MDA: "//assets.ctfassets.net/ukstf33kj625/6pKoCb6PCiwaTEjOTswokB/86bd84c639e995ea79ab5aec06cad1df/Kadestone_Capital_Corp._-_MDA_-_Mar_31_2022_-_Final.PDF",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/1jxFYSG5ElTKrAtd4wvMJO/f24ea1a538783d3b9699a2fb8dfc2a7e/Kadestone_Capital_Corp._-_FS_-_Mar_31_2022_-_Final.PDF",
        },
      ],
    },
    {
      year: 2021,
      documents: [
        {
          label: "Year End",
          // these urls are supposedly consistent...
          MDA: "//assets.ctfassets.net/ukstf33kj625/7shc2YTd74czYqd5mY0PHX/5c51e898af46b8c0db4b27f2461486ba/Kadestone_Capital_Corp._-_MDA_-_Dec_31_2021_-_Final.PDF",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/33OsJS9wLLWAk0VvM2wpAu/59aa31afb29f564667dba2d1337d34a4/Kadestone_Capital_Corp._-_FS_-_Dec_31_2021_-_Final.PDF",
        },
        {
          label: "Third Quarter",
          // these urls are supposedly consistent...
          MDA: "//assets.ctfassets.net/ukstf33kj625/3qgJb3dtP5jWdkJJ5hh75i/3fad70cf03a7f3974f5d1abd96af67ce/Kadestone_Capital_Corp._-_MDA_-_Sep_30_2021_-_Draft_4.PDF",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/6ND1oVccBbUNcAJ5WnPfL5/f1af8e08688feaf8060ee9777a3ba405/Kadestone_Capital_Corp._-_FS_-_Sep_30_2021_-_Draft_4.PDF",
        },
        {
          label: "Second Quarter",
          // these urls are supposedly consistent...
          MDA: "//assets.ctfassets.net/ukstf33kj625/3YV0fvDQ1TmuYWEd5LZGZH/7cf1d163bf3d59ce563c730d3d112438/Kadestone_Capital_Corp._-_MDA_-_Jun_30_2021_-_Final.pdf",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/3QoPdMLAZYzu5Y4kfVcerm/58a1f122d4b72e3375c74a7edfa6fb8e/Kadestone_Capital_Corp._-_FS_-_Jun_30_2021_-_Final.pdf",
        },
        {
          label: "First Quarter",
          // these urls are supposedly consistent...
          MDA: "//assets.ctfassets.net/ukstf33kj625/14bRIR3VzeoFZSwI4PgiGZ/aca8e00e84a41b9ba836344303fc1664/Kadestone_Capital_Corp._-_MDA_-_Mar_31_2021_-_Final.pdf",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/7dAhxWUMNayBPfYnCeWGpX/b7fcde279bd5faef1311119490b6e999/Kadestone_Capital_Corp._-_FS_-_Mar_31_2021_-_Final.pdf",
        },
      ],
    },
    {
      year: 2020,
      documents: [
        {
          label: "Year End",
          // these urls are supposedly consistent...
          MDA: "//assets.ctfassets.net/ukstf33kj625/4VfNrNxr1kg3Sj7C9SCQ5M/3c0f3e988816a34671b25229dd0ec484/Kadestone_Capital_Corp._-_MDA_-_Dec_31_2020_-_Final.pdf",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/1XeFJLLAxs45y7KS3OBkB3/e08b59a2654bc47e2bd97e12d5367a38/Kadestone_Capital_Corp._-_FS_-_Dec_31_2020_-_Final.pdf",
        },
        {
          label: "Third Quarter",
          // these urls are supposedly consistent...
          MDA: "//assets.ctfassets.net/ukstf33kj625/4iFCvHJt528QZPHNCKUcIW/321175f02f3774c53781882d7efa2c32/Q3_2020_MDA_-_Final.pdf",
          financial:
            "//assets.ctfassets.net/ukstf33kj625/6QqNW0LY947tDG6bNUiI8U/6f94583ba806cc1450d3a642a0f3557e/Q3_2020_FS_-_Final.pdf",
        },
      ],
    },
  ];

  return (
    <div className={classes.docRoot}>
      <Typography variant="subtitle1" className={clsx(classes.title)}>
        Financial Results
      </Typography>
      <br />
      <div className={classes.pageMax}>
        {assets
          ? data.map((x) => (
              <div key={x.year} className={classes.quarterWrapper}>
                <div
                  className={clsx([classes.quarterLabel, classes.yearLabel])}
                >
                  {x.year}
                </div>
                {x.documents.map(({ label, financial, MDA }) => (
                  <div
                    key={`${x.year}-${label}`}
                    className={classes.quarterInfo}
                  >
                    <span className={classes.quarterLabel}>{label}</span>
                    <a
                      href={financial}
                      className={classes.documentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {!matches && (
                        <img
                          src={pdfIcon}
                          className={classes.pdfIcon}
                          alt="Financial"
                        />
                      )}{" "}
                      Financial Statements
                    </a>
                    <a
                      href={MDA}
                      className={classes.documentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {!matches && (
                        <img
                          src={pdfIcon}
                          className={classes.pdfIcon}
                          alt="MDA PDF Icon"
                        />
                      )}{" "}
                      Management Discussion and Analysis
                    </a>
                  </div>
                ))}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

const FinancialResults = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.title}>
        Share Price
      </Typography>
      <div className={classes.separator} />
      <Typography
        component="a"
        href="https://web.tmxmoney.com/quote.php?qm_symbol=KDSX"
      >
        TSXV: KDSX
      </Typography>
      <div className={classes.content}>
        <StockPrice />
        <Typography className={classes.stockDisclaimer}>
          The above stock summary is delayed, and it does not reflect real time
          stock prices. Kadestone Capital Corp. does not make, and expressly
          disclaims, any warranty, express or implied, statutory or otherwise,
          as to the site you are about to link to, including but not limited to,
          any content of the site or sites your are linking to.
        </Typography>
      </div>
      <FinancialDocuments />
      <Typography
        component="a"
        href="https://www.sedar.com/DisplayProfile.do?lang=EN&issuerType=03&issuerNo=00049562"
        target="_blank"
        rel="noopenner noreferrer"
        className={classes.sedar}
      >
        Link to SEDAR filings <OpenInNewIcon />
      </Typography>
    </div>
  );
};

FinancialResults.propTypes = {};
FinancialResults.defaultProps = {};

export default FinancialResults;
